<template>
  <div class="privacy" v-scrollintoview>
    <div class="view-container">
      <div class="privacy-page" v-if="lang == 'en'">
        <div class="page-title">CBibank privacy policy</div>
        <div class="page-content">
          <div class="content-text">CB International Bank reminds you that this statement is provided to you by CB International Bank (hereinafter referred to as "/our bank / we") and contains important agreements involving your personal information rights and interests. Please read it carefully before providing personal information.</div>
          <div class="content-text">
            Our bank attaches great importance to the protection of user information. Please read this statement carefully before you use our relevant services (hereinafter referred to as "services") and fill in your personal information on the corresponding page.
            <span class="bold"> Once you take the initiative to provide your personal information on the page and submit it, it means that you agree that we will collect and use your relevant information in accordance with this statement.</span>
          </div>
          <div class="content-text">Part I: Collection of personal information</div>
          <div class="content-text">1. You agree that we can use personal information in the following ways:</div>
          <div class="content-text">The information you provide in using the forms and other functions involved in this page, including name, mobile number, email, enterprise name, demand options, social application account and necessary information related to using the products / services provided on this page.</div>
          <div class="content-text">Please note that our bank will explain the collection and use rules of relevant personal information to you below.</div>
          <div class="content-text">2. You should note that the above personal information is provided by you voluntarily. You have the right to refuse to provide, but you may not be able to normally use the corresponding services, functions or achieve the intended effect of the service.</div>
          <div class="content-text">Part II: Use of personal information</div>
          <div class="content-text">1. You agree that we can use personal information in the following ways:</div>
          <div class="content-text">(1) We collect your name, mobile number, email address and social application account to contact you in time and communicate with you about products or services.</div>
          <div class="content-text">(2) In order to provide better services, when you communicate with our bank by phone or customer service tools, our bank will record your mobile phone number and relevant communication.</div>
          <div class="content-text">(3) If you want to inquire, modify, delete or withdraw the personal information you are authorized to submit, or have any questions or complaints about the protection of personal information, you can contact us and we will cooperate as soon as possible.</div>
          <div class="content-text">2. We will strictly protect your personal information security. We will adopt appropriate systems, organizations, security technologies and other measures to protect your personal information from unauthorized access, use or disclosure.</div>
          <div class="content-text">3. In order to achieve the above purpose, our bank may entrust our technical partners (such as call service providers) to provide services for you, and they will use them legally, properly and necessarily within the scope of services.</div>
          <div class="content-text">1. Information collected during your use of products or services.</div>
          <div class="content-text">Our bank may collect information about your use of products and / or services (including use methods), including:</div>
          <div class="content-text">(1) Local storage. Our bank may use browser network storage and other mechanisms (including HTML5) and application data cache to collect information on your device and store it locally.</div>
          <div class="content-text">(2) Tools such as cookies and anonymous identifiers. The main functions of cookies are to facilitate your use of website products and / or services, and to help the website count the number of independent visitors.</div>
          <div>(3) Website access to Google Analytics</div>
          <div>Name: Google Analytics </div>
          <div>Function: used for data statistical analysis </div>
          <div>Involving personal information: IP address ，Device ID </div>
          <div class="content-text">Google Analytics privacy policy link: https://support.google.com/analytics/answer/6004245?hl=zh -Hans</div>
          <div>(4) Website access Google tracking code manager</div>
          <div>Name: Google tracking code manager </div>
          <div>Function: used for data statistical analysis </div>
          <div>Involving personal information: IP address ，Device ID </div>
          <div class="content-text">Google tracking code manager privacy policy link: https://support.google.com/tagmanager/answer/9323295?hl=zh -Hans</div>
          <div>(5) Clear cookies</div>
          <div class="content-text">Most browsers provide users with the function of clearing browser cache data. You can clear the corresponding data in the browser setting function. If you clear it, you may not be able to use the services or corresponding functions that rely on cookies provided by the company due to these modifications.</div>
          <div>Attachment: term explanation cookie</div>
          <div>Cookie</div>
          <div>A mechanism that supports the server (or script) to store and retrieve information on the client. It extends Web-based client / server applications by adding simple and continuous client state. The server sends a status message while returning the HTTP object to the client, which is saved by the client. The status information describes the valid URL range in this status. Thereafter, HTTP requests within this range initiated by the client will return the current value of the status information from the client to the server. This status information is called a cookie.</div>
          <div class="content-text">The above data information is anonymous. At the same time, we will also encrypt the information to ensure the security of the information.</div>
          <div class="content-text">Part III: Agreement on personal information of minors</div>
          <div class="content-text">1. Expect parents or guardians to guide minors to use services. Minors may not create their own user accounts without the consent of parents or guardians. The confidentiality and security of minors' personal information will be protected in accordance with relevant national laws and regulations.</div>
          <div class="content-text">2. If you are a minor, your parents or guardians should read this Privacy Policy and use the services or provide your information with the consent of your parents or guardians. For the information collected with the consent of your parents or guardians, we will only use this information which permitted by the law. If your guardian does not agree with the services you use or provide information to in accordance with this privacy policy, please immediately terminate the services you use and notify in time so that appropriate measures can be taken.</div>
          <div class="content-text">3. If you are the parent or guardian of a minor, please read this privacy policy carefully. If you have any questions about the personal information processing of the minors under your supervision, please contact through the contact information in this privacy policy If you are the guardian of a minor under the age of 14, you should read and agree to this Privacy Policy for your ward before using this function.</div>
          <div class="content-text">Part IV: Change</div>
          <div>Our bank will revise this statement from time to time, and such amendments form a part of this statement. Our bank will issue the revised statement. In this case, if you continue to use our services, you agree to be bound by the revised statement.</div>
        </div>
      </div>
      <div class="privacy-page" v-else-if="lang == 'zh-tw'">
        <div class="page-title">富港银行（CBiBank）隐私政策</div>
        <div class="page-content">
          <div class="content-text">富港銀行/CB International Bank提醒您，該聲明由富港銀行/CB International Bank（即下文的“/我行/我們”）向您提供，包含涉及您個人信息權益的重要約定，請您在提供個人信息前仔細閱讀。</div>
          <div class="content-text">我行非常重視用戶信息的保護。您在使用我行的相關服務（以下簡稱“服務”），並在相應頁面填寫個人信息前，請您務必仔細閱讀本聲明。您一旦主動在頁面提供您的個人信息並進行提交操作，即意味著您同意我行按照本聲明收集、 使用您的相關信息。</div>
          <div class="content-text">一、個人信息的收集</div>
          <div class="content-text">1、我行提供服務時，可能會收集下列與您有關的個人信息：</div>
          <div class="content-text">您在使用本頁面所涉及的表單等功能中提供的信息，包括姓名、手機號、郵箱、企業名稱、需求選項、社交應用賬號及使用本頁面提供的產品/服務涉及的必要信息。</div>
          <div class="content-text">請您注意，我行會在下文向您說明相關個人信息的收集和使用規則。</div>
          <div class="content-text">2、您需要注意的是，以上個人信息均是您自願提供。您有權拒絕提供，但可能無法正常使用相應的服務、功能或者無法達到服務擬達到的效果。</div>
          <div class="content-text">二、個人信息的使用</div>
          <div class="content-text">1、您同意，我行可以通過以下方式對個人信息進行使用：</div>
          <div class="content-text">（1）我行收集您的姓名、手機號、郵箱、社交應用賬號用於及時與您取得聯繫，並與您溝通產品或服務的相關事宜。</div>
          <div class="content-text">（2）為了更好的提供服務，您在通過電話或客服工具與我行溝通時，我行會記錄您的手機號及相關溝通情況。</div>
          <div class="content-text">（3）如您擬查詢、修改、刪除、撤回授權您提交的個人信息的，或對個人信息保護問題有任何疑問或投訴，您可以與我行聯繫，我行將盡快配合處理。</div>
          <div class="content-text">2、我行將嚴格保護您的個人信息安全。我行將採用適當制度、組織和安全技術等措施來保護您的個人信息不被未經授權的訪問、使用或洩漏。</div>
          <div class="content-text">3、為了實現上述目的，我行可能會委託我行的技術合作夥伴（例如呼叫服務商）為您提供服務，並由其在服務範圍內進行合法、正當、必要地使用。</div>
          <div class="content-text">1. 在您使用產品或服務過程中收集的信息。</div>
          <div class="content-text">我行可能會收集關於您使用產品和/或服務（包括使用 方式）過程中的信息，此類信息包括：</div>
          <div class="content-text">（1） 本地存儲。我行可能會使用瀏覽器網絡存儲等機制 （包括HTML5）和應用數據緩存，收集您設備上的信 息並進行本地存儲。</div>
          <div class="content-text">（2） Cookie和匿名標示符等工具。cookie主要的功能是 便於您使用網站產品和/或服務，以及幫助網站統計獨 立訪客數量等。</div>
          <div>（3）網站接入Google Analytics</div>
          <div>名稱：Google Analytics</div>
          <div>功能：用於數據統計分析</div>
          <div>涉及個人信息：IP地址、UA、設備信息</div>
          <div class="content-text">Google Analytics 隱私政策鏈接：https://support.google.com/analytics/answer/6004245?hl=zh-Hans</div>
          <div>（4）網站接入Google 跟踪代碼管理器</div>
          <div>名稱：Google 跟踪代碼管理器</div>
          <div>功能：用於數據統計分析</div>
          <div>涉及個人信息：IP地址、UA、設備信息</div>
          <div class="content-text">Google 跟踪代碼管理器隱私政策鏈接：https://support.google.com/tagmanager/answer/9323295?hl=zh-Hans</div>
          <div>（5）Cookie的清除</div>
          <div class="content-text">大多數瀏覽器均為用戶提供了清除瀏覽器緩存數據的功能，您可以在瀏覽器設置功能中進行相應的數據清除操作。如您進行清除，可能因為這些修改，您可能無法使用依賴於Cookie由公司提供的服務或相應功能。</div>
          <div>附：名詞解釋Cookie</div>
          <div>Cookie</div>
          <div>支持服務器端（或者腳本）在客戶端上存儲和檢索信息 的一種機制，通過增加簡單、持續的客戶端狀態來擴展 基於Web的客戶端/服務器應用。服務器在向客戶端返 回HTTP對象的同時發送一條狀態信息，並由客戶端保 存。狀態信息中說明了該狀態下有效的URL範圍。此 後，客戶端發起的該範圍內的HTTP請求都將把該狀態 信息的當前值從客戶端返回給服務器，這個狀態信息被 稱為cookie。</div>
          <div class="content-text">以上數據信息都採用匿名的方式。同時，我行也會對信息採取加密處理，保證信息的安全性。</div>
          <div class="content-text">三、對未成年人個人信息的約定</div>
          <div class="content-text">1.期望父母或監護人指導未成年人使用的服務，未經父母或監護人同意，未成年人不得創建自己的用戶賬戶。將根據國家相關法律法規的規定保護未成年人的個人信息的保密性及安全性。</div>
          <div class="content-text">2.如您為未成年人，請您的父母或監護人閱讀本隱私政策，並在徵得您父母或監護人同意的前提下使用的服務或向提供您的信息。對於經父母或監護人同意而收集您的信息的情況，只會在受到法律允許、父母或監護人明確同意或者保護您的權益所必要的情況下使用或公開披露此信息。如您的監護人不同意您按照本隱私政策使用的服務或向提供信息，請您立即終止使用的服務並及時通知，以便採取相應的措施。</div>
          <div class="content-text">3.如您為未成年人的父母或監護人，請您仔細閱讀本隱私政策。當您對您所監護的未成年人的個人信息處理存在疑問時，請通過本隱私政策中的聯繫方式聯繫。 、若您是未滿14周歲的未成年人的監護人，在使用本功能前，應為您的被監護人閱讀並同意本隱私政策。</div>
          <div class="content-text">四、變更</div>
          <div>我行會適時對本聲明進行修訂，該等修訂構成本聲明的一部分。我行會將修改後的聲明予以發布。在該種情況下，若您繼續使用我行的服務，即表示同意接受經修訂的聲明的約束。</div>
        </div>
      </div>
      <div class="privacy-page" v-else>
        <div class="page-title">富港银行（CBiBank）隐私政策</div>
        <div class="page-content">
          <div class="content-text">富港银行/CB International Bank提醒您，该声明由富港银行/CB International Bank（即下文的“/我行/我们”）向您提供，包含涉及您个人信息权益的重要约定，请您在提供个人信息前仔细阅读。</div>
          <div class="content-text">我行非常重视用户信息的保护。您在使用我行的相关服务（以下简称“服务”），并在相应页面填写个人信息前，请您务必仔细阅读本声明。您一旦主动在页面提供您的个人信息并进行提交操作，即意味着您同意我行按照本声明收集、 使用您的相关信息。</div>
          <div class="content-text">一、个人信息的收集</div>
          <div class="content-text">1、我行提供服务时，可能会收集下列与您有关的个人信息：</div>
          <div class="content-text">您在使用本页面所涉及的表单等功能中提供的信息，包括姓名、手机号、邮箱、企业名称、需求选项、社交应用账号及使用本页面提供的产品/服务涉及的必要信息。</div>
          <div class="content-text">请您注意，我行会在下文向您说明相关个人信息的收集和使用规则。</div>
          <div class="content-text">2、您需要注意的是，以上个人信息均是您自愿提供。您有权拒绝提供，但可能无法正常使用相应的服务、功能或者无法达到服务拟达到的效果。</div>
          <div class="content-text">二、个人信息的使用</div>
          <div class="content-text">1、您同意，我行可以通过以下方式对个人信息进行使用：</div>
          <div class="content-text">（1）我行收集您的姓名、手机号、邮箱、社交应用账号用于及时与您取得联系，并与您沟通产品或服务的相关事宜。</div>
          <div class="content-text">（2）为了更好的提供服务，您在通过电话或客服工具与我行沟通时，我行会记录您的手机号及相关沟通情况。</div>
          <div class="content-text">（3）如您拟查询、修改、删除、撤回授权您提交的个人信息的，或对个人信息保护问题有任何疑问或投诉，您可以与我行联系，我行将尽快配合处理。</div>
          <div class="content-text">2、我行将严格保护您的个人信息安全。我行将采用适当制度、组织和安全技术等措施来保护您的个人信息不被未经授权的访问、使用或泄漏。</div>
          <div class="content-text">3、为了实现上述目的，我行可能会委托我行的技术合作伙伴（例如呼叫服务商）为您提供服务，并由其在服务范围内进行合法、正当、必要地使用。</div>
          <div class="content-text">1. 在您使用产品或服务过程中收集的信息。</div>
          <div class="content-text">我行可能会收集关于您使用产品和/或服务（包括使用 方式）过程中的信息，此类信息包括：</div>
          <div class="content-text">（1） 本地存储。我行可能会使用浏览器网络存储等机制 （包括HTML5）和应用数据缓存，收集您设备上的信 息并进行本地存储。</div>
          <div class="content-text">（2） Cookie和匿名标示符等工具。cookie主要的功能是 便于您使用网站产品和/或服务，以及帮助网站统计独 立访客数量等。</div>
          <div>（3）网站接入Google Analytics</div>
          <div>名称：Google Analytics </div>
          <div>功能：用于数据统计分析</div>
          <div>涉及个人信息：IP地址、UA、设备信息</div>
          <div class="content-text">Google Analytics 隐私政策链接：https://support.google.com/analytics/answer/6004245?hl=zh-Hans</div>
          <div>（4）网站接入Google 跟踪代码管理器</div>
          <div>名称：Google 跟踪代码管理器 </div>
          <div>功能：用于数据统计分析</div>
          <div>涉及个人信息：IP地址、UA、设备信息</div>
          <div class="content-text">Google 跟踪代码管理器隐私政策链接：https://support.google.com/tagmanager/answer/9323295?hl=zh-Hans</div>
          <div>（5）Cookie的清除</div>
          <div class="content-text">大多数浏览器均为用户提供了清除浏览器缓存数据的功能，您可以在浏览器设置功能中进行相应的数据清除操作。如您进行清除，可能因为这些修改，您可能无法使用依赖于Cookie由公司提供的服务或相应功能。</div>
          <div>附：名词解释Cookie</div>
          <div>Cookie</div>
          <div>支持服务器端（或者脚本）在客户端上存储和检索信息 的一种机制，通过增加简单、持续的客户端状态来扩展 基于Web的客户端/服务器应用。服务器在向客户端返 回HTTP对象的同时发送一条状态信息，并由客户端保 存。状态信息中说明了该状态下有效的URL范围。此 后，客户端发起的该范围内的HTTP请求都将把该状态 信息的当前值从客户端返回给服务器，这个状态信息被 称为cookie。</div>
          <div class="content-text">以上数据信息都采用匿名的方式。同时，我行也会对信息采取加密处理，保证信息的安全性。</div>
          <div class="content-text">三、对未成年人个人信息的约定</div>
          <div class="content-text">1.期望父母或监护人指导未成年人使用的服务，未经父母或监护人同意，未成年人不得创建自己的用户账户。将根据国家相关法律法规的规定保护未成年人的个人信息的保密性及安全性。</div>
          <div class="content-text">2.如您为未成年人，请您的父母或监护人阅读本隐私政策，并在征得您父母或监护人同意的前提下使用的服务或向提供您的信息。对于经父母或监护人同意而收集您的信息的情况，只会在受到法律允许、父母或监护人明确同意或者保护您的权益所必要的情况下使用或公开披露此信息。如您的监护人不同意您按照本隐私政策使用的服务或向提供信息，请您立即终止使用的服务并及时通知，以便采取相应的措施。</div>
          <div class="content-text">3.如您为未成年人的父母或监护人，请您仔细阅读本隐私政策。当您对您所监护的未成年人的个人信息处理存在疑问时，请通过本隐私政策中的联系方式联系。、若您是未满14周岁的未成年人的监护人，在使用本功能前，应为您的被监护人阅读并同意本隐私政策。</div>
          <div class="content-text">四、变更</div>
          <div>我行会适时对本声明进行修订，该等修订构成本声明的一部分。我行会将修改后的声明予以发布。在该种情况下，若您继续使用我行的服务，即表示同意接受经修订的声明的约束。</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Index',
  computed: {
    ...mapGetters(['lang'])
  }
}
</script>

<style scoped lang="less">
.privacy {
  padding-top: 98px;
  padding-bottom: 229px;
  background-color: #F7F8FA;
  .privacy-page {
    padding: 60px 30px;
    font-size: 15px;
    font-weight: 300;
    color: #1A1A1A;
    line-height: 30px;
    background-color: #FFFFFF;
    .page-title {
      padding-bottom: 40px;
      border-bottom: 1px solid rgba(0,0,0,0.16);
      font-size: 18px;
      font-weight: 400;
      color: #000000;
      line-height: 18px;
      text-align: center;
    }
    .page-content {
      padding-top: 40px;
      .content-text {
        padding-bottom: 20px;
      }
    }
  }
}
@media screen and (max-width: @mobileWidth) {
  .privacy {
    padding-top: 30px;
    padding-bottom: 80px;
    background-color: #F7F8FA;
    .privacy-page {
      padding: 20px 15px;
      font-size: 12px;
      font-weight: 300;
      color: #1A1A1A;
      line-height: 20px;
      background-color: #FFFFFF;
      .page-title {
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(0,0,0,0.16);
        font-size: 16px;
        font-weight: 400;
        color: #000000;
        line-height: 16px;
        text-align: center;
      }
      .page-content {
        padding-top: 25px;
        .content-text {
          padding-bottom: 20px;
        }
      }
    }
  }
}

</style>
